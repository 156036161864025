@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

html, body {
    box-sizing: border-box;
    height: 100%;
    font-family: poppins, serif;
    font-size: 1rem;
    background: #F7F9FF;
    zoom: 100% !important;
}

nav.navbar.bg-dark {
    background: #07bb71 !important;
    padding: 0.5rem 1.5rem !important;
}

nav.navbar.bg-dark .header-logo.img-thumbnail {
    background: transparent !important;
    border: none !important;
}

nav.navbar.bg-light {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15) !important;
    padding: 0.5rem 2rem;
}

nav.navbar.bg-light .navbar-nav {
    column-gap: 3rem;
}

.navbar-light .navbar-nav .nav-link {
    color: #222222 !important;
    font-weight: 500 !important;
    border-bottom: 2px solid transparent !important;
    border-radius: 0 !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.nav-active {
    color: #5e72e4 !important;
    background-color: transparent !important;
    border-bottom: 2px solid #5e72e4 !important;
}

.navbar-light .navbar-nav .sub-header.nav-item.dropdown {
    padding-bottom: 0 !important;
}

.navbar-light .navbar-nav .dropdown-menu.show {
    border-radius: 0.5rem !important;
}

.navbar-light .navbar-nav .dropdown-menu.show .dropdown-item:last-child {
    border: none;
}

.navbar-light .navbar-nav .dropdown-menu.show .dropdown-item:hover {
    background-color: #eaeaea !important;
}

.container-fluid > .nav-tabs .nav-link,
.tab-content > .tab-pane .nav-tabs .nav-link {
    border: 2px solid #04AA6D !important;
    color: #04AA6D !important;
    font-size: 1.1rem !important;

}

.container-fluid > .nav-tabs .nav-link:hover,
.container-fluid > .nav-tabs .nav-link.active,
.tab-content > .tab-pane .nav-tabs .nav-link:hover,
.tab-content > .tab-pane .nav-tabs .nav-link.active {
    background: #04AA6D !important;
    color: #f2f2f2 !important;
}

.tab-content > .tab-pane .tab-content {
    margin-top: 2rem !important;
}

.tab-content > .tab-pane .tab-content .list-group-item{
    color: #222222 !important;
}

.tab-content > .tab-pane .tab-content .list-group-item:hover {
    background-color: rgba(4, 170, 109, 0.5) !important;
}

.user-dropdown a.dropdown-toggle div {
    background: #f7f7f7 !important;
}

.user-dropdown a.dropdown-toggle p {
    color: #222222 !important;
    font-weight: 500;
    line-height: 1 !important;
}

.container-fluid > .row:first-child h4 {
    display: none;
}

.container-fluid > .row .card .card-body {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.container-fluid > .row .card .card-body div.h5 {
    color: #222222;
    font-size: 1.1rem;
    margin-bottom: 0;
}

.container-fluid > .row .card .card-body .row {
    width: 100%;
}

.container-fluid > .row .card .card-body .row .col {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.container-fluid > .row .card .card-body .row .col .form-control {
    width: 70%;
}

.container-fluid > .row .card .card-body > div:nth-child(2) {
    width: 100%;
}

.container-fluid > .card {
    margin-top: 2rem;
}

.card-header h3 {
    font-size: 1.5rem;
}

.card-header > div {
    display: flex;
    gap: 1rem;
}

.card-header .btn-outline-info :nth-child(2) {
    display: none;
}

.card-header .btn-outline-info {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
}

.fixed-table-toolbar .btn-group {
    display: none;
}

.react-bootstrap-table {
    margin-top: 1rem;
}

.react-bootstrap-table table {
    font-size: 1rem !important;
}

.react-bootstrap-table table thead th {
    background: #04AA6D !important;
    color: #f2f2f2 !important;
    padding: 1rem;
    font-size: 1rem;
}

.react-bootstrap-table table tr:hover td {
    background: #eaeaea !important;
}

.react-bootstrap-table table tr td {
    font-size: 1rem !important;
}

.react-bootstrap-table table tr td button,
.react-bootstrap-table table tr td a button {
    padding: 0.8rem 1.3rem;
}

.react-bootstrap-table table tr td button svg,
.react-bootstrap-table table tr td a button svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
}

.react-bootstrap-table table tr td .badge {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    width: 130px;
}

.react-bootstrap-table-pagination-total {
    font-size: 1.2rem;
}

ul.pagination {
    column-gap: 0.8rem;
}

ul.pagination li {
    border-radius: 0 !important;
    border: none !important;
    width: auto !important;
}

ul.pagination li a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #222222;
    width: 50px;
    height: 50px;
    color: #222222 !important;
    border-radius: 0.5rem !important;
}

ul.pagination li:hover,
ul.pagination li.active {
    background: #04AA6D;
    border-radius: 0.5rem !important;
}

ul.pagination li:hover a,
ul.pagination li.active a {
    border-color: #04AA6D !important;
    color: #ffffff !important;
}

ul.pagination li.break-me a,
ul.pagination li.previous.disabled a,
ul.pagination li.next.disabled a {
    background: #e2e6ea !important;
    border-color: #dae0e5 !important;
    color: #21252996 !important;
    pointer-events: none;
}

.btn-outline-info {
    padding: 0.7rem 1rem;
    font-size: 1rem;
}

.btn-outline-info:hover {
    background: #20c997;
    border-color: #20c997 !important;
}

.btn-primary {
    background: #06c9b9 !important;
    border-color: #06c9b9 !important;
}

.btn-outline-secondary {
    border-color: #06c9b9 !important;
}

.btn-outline-secondary:hover {
    background: #06c9b9 !important;
    color: #000000;
}

.card-header .btn-primary {
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
    background: transparent !important;
    border-color: #20c997 !important;
    color: #20c997 !important;;
    padding: 0.7rem 1rem;
    font-size: 1rem;
}

.card-header .btn-primary svg {
    color: #20c997 !important;
}

.card-header .btn-primary:hover {
    background: #20c997 !important;
    color: #ffffff !important;
}

.card-header .btn-primary :nth-child(2) {
    display: none;
}

.d-flex.align-items-center.justify-content-end nav {
    display: none;
}


iframe {
    display: none !important;
}