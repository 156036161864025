.react-calendar{
    width:unset !important;
    border:unset !important;
}

.search-group:focus{
    box-shadow: unset !important;
    border-color: #ced4da !important;
}

.list-group-item{
    cursor: pointer;
}

.list-group-item:hover{
    background-color: blanchedalmond;
}

.specialization{
    height: 300px;
    overflow: auto;
}

.schedule .btn-primary:focus{
    background-color: #06c9b9;
    border-color: transparent;
}

.height-500{
    height: 500px;
    overflow-y: auto;
}


.patient-sticker{
    font-family: 'Poppins';
    font-weight: bold;
}
/* 
.claim-bill{
    font-family: 'Open Sans', sans-serif !important;
    font-size:16px;
    font-weight: bold;
} */


.text-dark , .text-dark h2{
    color: black !important;
}


.lab-bill-table td, .lab-bill-table th{
    font-size: 17px !important;
    padding: 3px !important;
}

.lab-bill{
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.lab-bill .name{
    font-size: 20px;
    font-weight: bold;
}

.lab-bill-table , .lab-bill-table td, .lab-bill-table th{
    border: 3px solid black !important;
}

.w-75{
    width: 75% !important;
}

.w-25{
    width: 25% !important;
}

tbody tr td{
    padding: 6px !important;
    font-size: 15px !important;
    white-space: pre-line !important;
}

#recept-print{
    width: 25cm;
}

/* Bill */

.opd-bill-logo-content{
    height: 0.8in !important;
    max-height: 0.8in !important;
    display: none;
}

.opd-cashier-name{
    display: none;
}

.bill-heading{
    padding-left: 200px;
}

.bill-heading-claim{
    padding-left: 250px;
}


/***********************************************************/
/**************        OPD General Bill     ***************/
/**********************************************************/

.general-margin{
    display: none !important;
}

.with-header .general-margin{
    display: block !important;
}

.with-header .opd-bill-logo-content{
    display: block !important;
}

.with-header .opd-cashier-name{
    display: block !important;
}

/**************************************************************** */
/*******************    OPD Doctor Bill     ********************* */
/**************************************************************** */

.doctor-bill-general-margin{
    display: block !important;
}

.opd-doctor-bill-logo-content{
    display: none !important;
}

.with-header .doctor-bill-general-margin{
    display: none !important;
}

.with-header .opd-doctor-bill-logo-content{
    display: flex !important;
}


.with-header .bill-heading{
    padding-left: 0px !important;
}

.with-header .bill-heading-claim{
    padding-left: 0px !important;
}

.barcode svg{
    margin-left: -10px !important;
}